import React, { useEffect, useState } from "react";
import BackButton from "./Buttons/BackButton/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import dataVal from "../data/Abis.json";
import { Spinner } from "reactstrap";

import { useWeb3React } from "@web3-react/core";

let ttoken = [];

export default function CompPlan() {
  const { account, isActive, connector } = useWeb3React();
  let navigate = useNavigate();

  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);

  const [userlevel, setUserLevel] = useState("...");
  const [rankDetails, setrankDetails] = useState({});
  const [rankloader, setrankloader] = useState(false);

  const IDENTITY_ADDR = process.env.REACT_APP_INDENTITY_ADDR;
  const IDENTITY_ABI = dataVal.identityabi;

  useEffect(() => {
    if (isActive) {
      console.log("isActive");
      getSummary();
    } else {
      console.log("inactive");
      if (!localStorage.getItem("acct")) navigate("/buy");
    }
  }, [account]);

  const getSummary = async () => {
    const identitycontractInstance = new web3.eth.Contract(
      IDENTITY_ABI,
      IDENTITY_ADDR
    );
    if (localStorage.getItem("acct") && account) {
      if (
        account.toLowerCase() ==
        process.env.REACT_APP_MASTER_PARENT.toLowerCase()
      ) {
        await identitycontractInstance.methods
          .fetchUser(account)
          .call()
          .then((res) => {
            setUserLevel(res.rank);
          });
      } else {
        await identitycontractInstance.methods
          .fetchUser(account)
          .call()
          .then((res) => {
            console.log("rank--", res);
            setUserLevel(res.rank);
            if (res.parent === "0x0000000000000000000000000000000000000000") {
              navigate(-1);
            }
          });
      }
    }
  };

  const getrankdetails = async (rank) => {
    const rankInstance = new web3.eth.Contract(
      dataVal.rankabi,
      process.env.REACT_APP_RANK_ADDR
    );
    await rankInstance.methods
      .fetchRank(rank)
      .call()
      .then((res) => {
        let perctconv = parseFloat(res[0]) / 10000;
        let prevrankdetails = {
          [rank]: {
            percent: parseFloat(perctconv),
            sales: web3.utils.fromWei(res[1], "ether"),
          },
        };
        let testobj = ttoken;
        testobj = Object.assign({}, testobj, prevrankdetails);
        ttoken = Object.assign({}, testobj, prevrankdetails);
        setrankDetails(testobj);
      });
  };

  useEffect(() => {
    let rankarray = new Array(15).fill();
    rankarray.map((e, i) => {
      i ? getrankdetails(i) : console.log("i not defined");
    });
  }, []);

  useEffect(() => {
    console.log("RANK DETAILS", rankDetails);
    var keyCount = Object.keys(rankDetails).length;
    keyCount == 14 ? setrankloader(true) : setrankloader(false);
  }, [rankDetails]);

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }

  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  return (
    <>
      <div class="mainsection">
        <div class="tabsec secpadding lightgrey-bg brtlr">
          <BackButton Title="补偿计划" ReRoute="refer" />
          <div class="wallettabsec lightgrey-bg brtlr">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link typetabbtn brltb active "
                  id="pills-rankbonues-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-rankbonues"
                  type="button"
                  role="tab"
                  aria-controls="pills-rankbonues"
                  aria-selected="true"
                >
                  等级奖金
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button
                  class="nav-link typetabbtn  brrtb"
                  id="pills-miningcredit-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-miningcredit"
                  type="button"
                  role="tab"
                  aria-controls="pills-miningcredit"
                  aria-selected="false"
                >
                  挖矿credits币
                </button>
              </li>
            </ul>
            {rankloader == false ? (
              <div style={{ padding: "50px", textAlign: "center" }}>
                <Spinner />
              </div>
            ) : (
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-rankbonues"
                  role="tabpanel"
                  aria-labelledby="pills-rankbonues-tab"
                  tabindex="0"
                >
                  <div class="rankbonustable table-responsive">
                    <table class="table table-bordered text-center">
                      <thead>
                        <tr class="darktrbg text-white">
                          <th>等级</th>
                          <th>百分比</th>
                          <th>差异</th>
                          <th>目标</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(rankDetails).map((key, i) => {
                          return i == 0 ? (
                            <tr
                              class={
                                userlevel == i + 1
                                  ? "whitebg active"
                                  : "whitebg"
                              }
                            >
                              <td>{i + 1}</td>
                              <td>{Object.values(rankDetails)[i].percent}%</td>
                              <td>0%</td>
                              <td>
                                $
                                {numFormatter(
                                  Object.values(rankDetails)[i].sales
                                )}
                              </td>
                            </tr>
                          ) : (
                            <tr
                              class={
                                i % 2 == 0
                                  ? userlevel == i + 1
                                    ? "whitebg active"
                                    : "whitebg"
                                  : userlevel == i + 1
                                    ? "lightdarkbg active"
                                    : "lightdarkbg"
                              }
                            >
                              <td>{i + 1}</td>
                              <td>{Object.values(rankDetails)[i].percent}%</td>
                              <td>
                                {Object.values(rankDetails)[i].percent -
                                  Object.values(rankDetails)[i - 1].percent}
                                %
                              </td>
                              <td>
                                $
                                {numFormatter(
                                  Object.values(rankDetails)[i].sales
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-miningcredit"
                  role="tabpanel"
                  aria-labelledby="pills-miningcredit-tab"
                  tabindex="0"
                >
                  <div class="rankbonustable table-responsive">
                    <table class="table table-bordered text-center">
                      <thead>
                        <tr class="darktrbg text-white">
                          <th>等级</th>
                          <th>直接CBK奖金</th>
                          <th>间接CBK奖金</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(rankDetails).map((key, i) => {
                          return i == 0 ? (
                            <tr
                              class={
                                userlevel == i + 1
                                  ? "whitebg active"
                                  : "whitebg"
                              }
                            >
                              <td>{i + 1}</td>
                              <td>10%</td>
                              <td>10%</td>
                            </tr>
                          ) : (
                            <tr
                              class={
                                i % 2 == 0
                                  ? userlevel == i + 1
                                    ? "whitebg active"
                                    : "whitebg"
                                  : userlevel == i + 1
                                    ? "lightdarkbg active"
                                    : "lightdarkbg"
                              }
                            >
                              <td>{i + 1}</td>
                              <td>10%</td>
                              <td>1%</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div class="lightblue-bg secpadding brblr mb20">
          <p class="text1">
          使用者每一次销售都能赚取两种类型收益。首先是用现金支付的等级奖金，使用者可以用它来支付矿工工资。第二是挖credits币 或者CBK，只能用来购买矿工。
          </p>
        </div>
      </div>
    </>
  );
}
