import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import dataVal from "../data/Abis.json";
import { MdClose } from "react-icons/md";
import { BiSolidCopyAlt } from "react-icons/bi";

import { useWeb3React } from "@web3-react/core";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function TreeModal(props) {
  const { account, isActive, connector } = useWeb3React();
  const [parent, setParent] = useState("...");
  const [rank, setRank] = useState("...");
  const [sales, setSales] = useState("...");
  const [summaryData, setSummaryData] = useState({ data: null, loading: true });

  const GOLD_TREASURY = process.env.REACT_APP_GOLD_CIRCLE_TREASURY;
  const TREASURY_ABI = dataVal.treasuryabi;
  const IDENTITY_ADDR = process.env.REACT_APP_INDENTITY_ADDR;
  const IDENTITY_ABI = dataVal.identityabi;
  const CONTRACT_ADDRESS = process.env.REACT_APP_INDENTITY_ADDR;

  const contractInstance = new web3.eth.Contract(
    IDENTITY_ABI,
    CONTRACT_ADDRESS
  );

  useEffect(() => {}, [props]);
  console.log("props", props); //to not round values while showing balance

  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const identitycontractInstance = new web3.eth.Contract(
      IDENTITY_ABI,
      IDENTITY_ADDR
    );
    if (props.address) {
      await identitycontractInstance.methods
        .fetchUser(props.address)
        .call()
        .then((res) => {
          if (res.parent !== "0x0000000000000000000000000000000000000000") {
            console.log("rankkk ", res.rank);
            setParent(res.parent);
            setRank(Number(res.rank));
            setSales(web3.utils.fromWei(res.sales, "ether"));
          }
        });
    }

    const url =
      "https://egold-treasury.tagdev.info/v1/summary/dashboard/" +
      props.address;

    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setSummaryData({ data: newData, loading: false });
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
  };

  return (
    <>
      <Modal
        isOpen={props.show}
        toggle={props.close}
        centered={true}
        size="md"
        backdrop="static"
        backdropClassName="connectmodalbg"
        className="treemodal"
      >
        <ModalBody style={{ background: "#fff", borderRadius: "25px" }}>
          <div className="card mx-0 me-0 " id="card4" style={{ marginTop: 0 }}>
            <div className="row px-3">
              <div
                className=" col-12 p-0"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="text12 mx-1">细节</p>
                <MdClose
                  style={{ fontSize: "25px", cursor: "pointer" }}
                  onClick={() => props.close()}
                />
              </div>
            </div>
            <hr className="mt-0" style={{ color: "#b0b6c0" }} />
            <div className="row px-4 mt-1 my-3 treemrow">
              <div className="col-3 cols-6 mob-mb-25 p-0 text-start">
                <p className="text5 m-0">
                地址{" "}
                  <BiSolidCopyAlt
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={async () => {
                      await navigator.clipboard.writeText(props.address);
                    }}
                  />
                </p>
                <p
                  className="text6 m-0 pt-2"
                  style={{
                    color: "#61D2F5",
                    cursor: "pointer",
                    paddingRight: "5px",
                  }}
                  onClick={() =>
                    window.open(
                      "https://bscscan.com/address/" + props.address,
                      "_blank"
                    )
                  }
                >
                  {props.address.slice(0, 4) +
                    "....." +
                    props.address.slice(-5)}
                </p>
              </div>
              <div className="col-3 cols-6 mob-mb-25 p-0 text-center">
                <p className="text5 m-0">
                家长{" "}
                  <BiSolidCopyAlt
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={async () => {
                      await navigator.clipboard.writeText(parent);
                    }}
                  />
                </p>
                <p
                  className="text6 m-0 pt-2"
                  style={{
                    color: "#61D2F5",
                    cursor: "pointer",
                    paddingLeft: "5px",
                  }}
                  onClick={() =>
                    window.open(
                      "https://bscscan.com/address/" + parent,
                      "_blank"
                    )
                  }
                >
                  {parent == "..."
                    ? parent
                    : parent.slice(0, 4) + "....." + parent.slice(-5)}
                </p>
              </div>
              <div className="col-3 cols-6  p-0 text-center">
                <p className="text5 m-0">等级</p>
                <p className="text6 m-0 pt-2 ">
                  {" "}
                  {rank == "..." ? "..." : rank == null ? "1" : rank}
                </p>
              </div>
              <div className="col-3 cols-6  p-0 text-center">
                <p className="text5 m-0">销售量(USD)</p>
                <p className="text6 m-0 pt-2">
                  {summaryData.loading === true || sales == "..."
                    ? "..."
                    : summaryData.data.dirAmount
                    ? noround(
                        parseFloat(summaryData.data.dirAmount) +
                          parseFloat(sales),
                        2
                      )
                    : "0.00"}
                </p>
              </div>
            </div>
            <div
              className="row earnedpsec"
              style={{
                borderRadius: "0px 0px 12px 12px",
                background: "#F3FFFE",
              }}
            >
              <p className="earnedp">
              该卡片展示了您选择的下线合作伙伴的表现和排名数据，供您参考和评估。它可以用来发现您的下线排名，计算您的下线排名
来自您合作伙伴销售的佣金。
              </p>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
