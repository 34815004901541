import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Spinner } from "reactstrap";

const TotaleSupply = () => {
  const [dataG, setDataG] = useState([]);
  const [dataS, setDataS] = useState([]);
  const [dataH, setDataH] = useState([]);
  const [dataX, setDataX] = useState([]);

  const [xcount1, setXcount1] = useState("...");
  const [xcount2, setXcount2] = useState("...");
  const [xcount3, setXcount3] = useState("...");
  const [xcount4, setXcount4] = useState("...");
  const [xcount5, setXcount5] = useState("...");
  const [xcount6, setXcount6] = useState("...");
  const [xcount7, setXcount7] = useState("...");
  const [xcount8, setXcount8] = useState("...");

  const [gcount1, setGcount1] = useState("...");
  const [gcount2, setGcount2] = useState("...");
  const [gcount3, setGcount3] = useState("...");
  const [gcount4, setGcount4] = useState("...");
  const [gcount5, setGcount5] = useState("...");
  const [gcount6, setGcount6] = useState("...");
  const [gcount7, setGcount7] = useState("...");
  const [gcount8, setGcount8] = useState("...");
  const [gcount9, setGcount9] = useState("...");
  const [gcount10, setGcount10] = useState("...");
  const [gcount11, setGcount11] = useState("...");
  const [gcount12, setGcount12] = useState("...");
  const [gcount13, setGcount13] = useState("...");
  const [gcount14, setGcount14] = useState("...");
  const [gmcount, setGmcount] = useState("...");

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const x1count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/21";
    const x2count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/22";
    const x3count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/23";
    const x4count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/24";
    const x5count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/25";
    const x6count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/26";
    const x7count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/27";
    const x8count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/28";

    const g1count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/1";
    const g2count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/2";
    const g3count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/3";
    const g4count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/4";

    const g5count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/5";
    const g6count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/6";
    const g7count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/7";
    const g8count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/8";
    const g9count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/9";
    const g10count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/10";
    const g11count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/11";
    const g12count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/12";
    const g13count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/13";
    const g14count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/14";

    const gmcount = "https://auth.egoldfarm.com/getgiftminercount";

    //FETCH X1
    fetch(x1count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("X DATA", newData.count);
        setXcount1(newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH X2
    fetch(x2count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("X DATA", newData.count);
        setXcount2(newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH X3
    fetch(x3count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("X DATA", newData.count);
        setXcount3(newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH X4
    fetch(x4count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("X DATA", newData.count);
        setXcount4(newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH X5
    fetch(x5count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("X DATA", newData.count);
        setXcount5(newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH X6
    fetch(x6count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("X DATA", newData.count);
        setXcount6(newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH X7
    fetch(x7count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("X DATA", newData.count);
        setXcount7(newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH X8
    fetch(x8count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("X DATA", newData.count);
        setXcount8(newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //FETCH G1
    fetch(g1count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("G DATA", newData.count);
        setGcount1(newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G2
    fetch(g2count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount2(newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G3
    fetch(g3count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount3(newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G4
    fetch(g4count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount4(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G5
    fetch(g5count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount5(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G6
    fetch(g6count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount6(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G7
    fetch(g7count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount7(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G8
    fetch(g8count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount8(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G9
    fetch(g9count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount9(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //FETCH G10
    fetch(g10count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount10(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G11
    fetch(g11count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount11(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G12
    fetch(g12count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount12(newData.count);
        console.log("GRR DATA 12", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G13
    fetch(g13count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount13(newData.count);
        console.log("GRR DATA 13", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G14
    fetch(g14count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount14(newData.count);
        console.log("GRR DATA 14", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH gift miner count
    fetch(gmcount)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGmcount(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
  };

  useEffect(() => {
    if (
      xcount1 !== "..." &&
      xcount2 !== "..." &&
      xcount3 !== "..." &&
      xcount4 !== "..." &&
      xcount5 !== "..." &&
      xcount6 !== "..." &&
      xcount7 !== "..." &&
      xcount8 !== "..." &&
      gcount1 !== "..." &&
      gcount2 !== "..." &&
      gcount3 !== "..." &&
      gcount4 !== "..." &&
      gcount5 !== "..." &&
      gcount6 !== "..." &&
      gcount7 !== "..." &&
      gcount8 !== "..." &&
      gcount9 !== "..." &&
      gcount10 !== "..." &&
      gcount11 !== "..." &&
      gcount12 !== "..." &&
      gcount13 !== "..." &&
      gcount14 !== "..." &&
      gmcount !== "..."
    ) {
      setDataG([
        {
          id: "G1",
          label: "G1",
          value: gcount1 * 20,
          color: "#d94126",
        },
        {
          id: "G2",
          label: "G2",
          value: gcount2 * 100,
          color: "#d94126",
        },
        {
          id: "G3",
          label: "G3",
          value: gcount3 * 500,
          color: "#d94126",
        },
        {
          id: "G4",
          label: "G4",
          value: gcount4 * 2500,
          color: "#d94126",
        },
        {
          id: "Other",
          label: "Other",
          value: gmcount * 6,
          color: "#d94126",
        },
      ]);
      setDataS([
        {
          id: "S1",
          label: "S1",
          value: gcount5 * 50,
          color: "#d94126",
        },
        {
          id: "S2",
          label: "S2",
          value: gcount6 * 250,
          color: "#d94126",
        },
        {
          id: "S3",
          label: "S3",
          value: gcount7 * 1250,
          color: "#d94126",
        },
        {
          id: "S4",
          label: "S4",
          value: gcount8 * 6250,
          color: "#d94126",
        },
        {
          id: "S5",
          label: "S5",
          value: gcount9 * 12500,
          color: "#d94126",
        },
      ]);
      setDataH([
        {
          id: "H1",
          label: "H1",
          value: gcount10 * 80,
          color: "#d94126",
        },
        {
          id: "H2",
          label: "H2",
          value: gcount11 * 500,
          color: "#d94126",
        },
        {
          id: "H3",
          label: "H3",
          value: gcount12 * 5000,
          color: "#d94126",
        },
        {
          id: "H4",
          label: "H4",
          value: gcount13 * 10000,
          color: "#d94126",
        },
        {
          id: "H5",
          label: "H5",
          value: gcount14 * 21000,
          color: "#d94126",
        },
      ]);
      setDataX([
        {
          id: "X1",
          label: "X1",
          value: xcount1 * 1000,
          color: "#d94126",
        },
        {
          id: "X2",
          label: "X2",
          value: xcount2 * 2000,
          color: "#d94126",
        },
        {
          id: "X3",
          label: "X3",
          value: xcount3 * 4000,
          color: "#d94126",
        },
        {
          id: "X4",
          label: "X4",
          value: xcount4 * 20000,
          color: "#d94126",
        },
        {
          id: "X5",
          label: "X5",
          value: xcount5 * 40000,
          color: "#d94126",
        },
        {
          id: "X6",
          label: "X6",
          value: xcount6 * 100000,
          color: "#d94126",
        },
        {
          id: "X7",
          label: "X7",
          value: xcount7 * 200000,
          color: "#d94126",
        },
        {
          id: "X8",
          label: "X8",
          value: xcount8 * 400000,
          color: "#d94126",
        },
      ]);
    }
  }, [
    xcount1,
    xcount2,
    xcount3,
    xcount4,
    xcount5,
    xcount6,
    xcount7,
    xcount8,
    gcount1,
    gcount2,
    gcount3,
    gcount4,
    gcount5,
    gcount6,
    gcount7,
    gcount8,
    gcount9,
    gcount10,
    gcount11,
    gcount12,
    gcount13,
    gcount14,
    gmcount,
  ]);

  const CenteredMetricX = ({ centerX, centerY }) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          fill="black"
          style={{
            fontSize: "20px",
            fontWeight: 700,
          }}
        >
          X 系列
        </text>
      </>
    );
  };
  const CenteredMetricS = ({ centerX, centerY }) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          fill="black"
          style={{
            fontSize: "20px",
            fontWeight: 700,
          }}
        >
          S 系列
        </text>
      </>
    );
  };

  const CenteredMetricG = ({ centerX, centerY }) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          fill="black"
          style={{
            fontSize: "20px",
            fontWeight: 700,
          }}
        >
          G 系列
        </text>
      </>
    );
  };

  const CenteredMetricH = ({ centerX, centerY }) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          fill="black"
          style={{
            fontSize: "20px",
            fontWeight: 700,
          }}
        >
          H 系列
        </text>
      </>
    );
  };

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return noround(num / 1000, 2) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return noround(num / 1000000, 2) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      {xcount1 == "..." ||
        xcount2 == "..." ||
        xcount3 == "..." ||
        xcount4 == "..." ||
        xcount5 == "..." ||
        xcount6 == "..." ||
        xcount7 == "..." ||
        xcount8 == "..." ||
        gcount1 == "..." ||
        gcount2 == "..." ||
        gcount3 == "..." ||
        gcount4 == "..." ||
        gcount5 == "..." ||
        gcount6 == "..." ||
        gcount7 == "..." ||
        gcount8 == "..." ||
        gcount9 == "..." ||
        gcount10 == "..." ||
        gcount11 == "..." ||
        gcount12 == "..." ||
        gcount13 == "..." ||
        gcount14 == "..." ||
        gmcount == "..." ? (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <Spinner />
        </div>
      ) : (
        <>
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation" style={{ width: "25%" }}>
              <button
                class="nav-link typetabbtn brltb active "
                id="pills-xseries-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-xseries"
                type="button"
                role="tab"
                aria-controls="pills-xseries"
                aria-selected="true"
              >
                X 系列
              </button>
            </li>
            <li class="nav-item" role="presentation" style={{ width: "25%" }}>
              <button
                class="nav-link typetabbtn"
                id="pills-sseries-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-sseries"
                type="button"
                role="tab"
                aria-controls="pills-sseries"
                aria-selected="true"
              >
                S 系列
              </button>
            </li>
            <li class="nav-item" role="presentation" style={{ width: "25%" }}>
              <button
                class="nav-link typetabbtn "
                id="pills-hseries-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-hseries"
                type="button"
                role="tab"
                aria-controls="pills-hseries"
                aria-selected="true"
              >
                H 系列
              </button>
            </li>

            <li class="nav-item" role="presentation" style={{ width: "25%" }}>
              <button
                class="nav-link typetabbtn  brrtb"
                id="pills-gseries-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-gseries"
                type="button"
                role="tab"
                aria-controls="pills-gseries"
                aria-selected="false"
              >
                G 系列
              </button>
            </li>
          </ul>
          <div className="chartbx">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-xseries"
                role="tabpanel"
                aria-labelledby="pills-xseries-tab"
                tabindex="0"
              >
                <div className="chbxhdiv">
                  <ResponsivePie
                    data={dataX}
                    margin={
                      window.innerWidth < 500
                        ? { top: 20, right: 20, bottom: 0, left: 20 }
                        : { top: 40, right: 80, bottom: 40, left: 80 }
                    }
                    valueFormat=" >-,"
                    startAngle={0}
                    sortByValue={false}
                    arcLinkLabelsStraightLength={15}
                    activeOuterRadiusOffset={8}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={20}
                    enableArcLinkLabels={window.innerWidth < 500 ? false : true}
                    enableArcLabels={window.innerWidth < 500 ? false : true}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                    colors={{ scheme: "yellow_orange_red" }}
                    innerRadius={0.45}
                    layers={[
                      "arcs",
                      "arcLabels",
                      "arcLinkLabels",
                      "legends",
                      CenteredMetricX,
                    ]}
                  />
                </div>
                <div class="detailCard secpadding mb20">
                  <div class="bardelshowp mb10">
                    <div class="sfirstbg"></div> X1 :{" "}
                    {parseFloat(noround(xcount1 * 1000, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div class="ssecbg"></div>X2 :{" "}
                    {parseFloat(noround(xcount2 * 2000, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div class="primarybardot"></div>X3 :{" "}
                    {parseFloat(noround(xcount3 * 4000, 2)).toLocaleString()}
                  </div>

                  <div class="bardelshowp mb10">
                    <div class="sfourbg"></div>X4 :{" "}
                    {parseFloat(noround(xcount4 * 20000, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div class="sfivedbg"></div>X5 :{" "}
                    {parseFloat(noround(xcount5 * 40000, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div class="ssixbg"></div>X6 :{" "}
                    {parseFloat(noround(xcount6 * 100000, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div class="ssevenbg"></div>X7 :{" "}
                    {parseFloat(noround(xcount7 * 200000, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div class="seightbg"></div>X8 :{" "}
                    {parseFloat(noround(xcount8 * 400000, 2)).toLocaleString()}
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-sseries"
                role="tabpanel"
                aria-labelledby="pills-sseries-tab"
                tabindex="0"
              >
                <div className="chbxhdiv">
                  <ResponsivePie
                    data={dataS}
                    margin={
                      window.innerWidth < 500
                        ? { top: 20, right: 20, bottom: 0, left: 20 }
                        : { top: 40, right: 80, bottom: 40, left: 80 }
                    }
                    valueFormat=" >-,"
                    startAngle={0}
                    sortByValue={false}
                    arcLinkLabelsStraightLength={15}
                    activeOuterRadiusOffset={8}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={20}
                    enableArcLinkLabels={window.innerWidth < 500 ? false : true}
                    enableArcLabels={window.innerWidth < 500 ? false : true}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                    colors={{ scheme: "yellow_orange_red" }}
                    innerRadius={0.45}
                    layers={[
                      "arcs",
                      "arcLabels",
                      "arcLinkLabels",
                      "legends",
                      CenteredMetricS,
                    ]}
                  />
                </div>
                <div class="detailCard secpadding mb20">
                  <div class="bardelshowp mb10">
                    <div class="sfirstbg"></div> S1 :{" "}
                    {parseFloat(noround(gcount5 * 50, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div class="ssecbg"></div>S2 :{" "}
                    {parseFloat(noround(gcount6 * 250, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div class="primarybardot"></div>S3 :{" "}
                    {parseFloat(noround(gcount7 * 1250, 2)).toLocaleString()}
                  </div>

                  <div class="bardelshowp mb10">
                    <div class="sthirdbg"></div>S4 :{" "}
                    {parseFloat(noround(gcount8 * 6250, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div class="sthirdbg"></div>S5 :{" "}
                    {parseFloat(noround(gcount9 * 12500, 2)).toLocaleString()}
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-hseries"
                role="tabpanel"
                aria-labelledby="pills-hseries-tab"
                tabindex="0"
              >
                <div className="chbxhdiv">
                  <ResponsivePie
                    data={dataH}
                    margin={
                      window.innerWidth < 500
                        ? { top: 20, right: 20, bottom: 0, left: 20 }
                        : { top: 40, right: 80, bottom: 40, left: 80 }
                    }
                    valueFormat=" >-,"
                    startAngle={0}
                    sortByValue={false}
                    arcLinkLabelsStraightLength={15}
                    activeOuterRadiusOffset={8}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={20}
                    enableArcLinkLabels={window.innerWidth < 500 ? false : true}
                    enableArcLabels={window.innerWidth < 500 ? false : true}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                    colors={{ scheme: "yellow_orange_red" }}
                    innerRadius={0.45}
                    layers={[
                      "arcs",
                      "arcLabels",
                      "arcLinkLabels",
                      "legends",
                      CenteredMetricH,
                    ]}
                  />
                </div>
                <div class="detailCard secpadding mb20">
                  <div class="bardelshowp mb10">
                    <div class="sfirstbg"></div> H1 :{" "}
                    {parseFloat(noround(gcount10 * 80, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div class="ssecbg"></div>H2 :{" "}
                    {parseFloat(noround(gcount11 * 500, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div class="primarybardot"></div>H3 :{" "}
                    {parseFloat(noround(gcount12 * 5000, 2)).toLocaleString()}
                  </div>

                  <div class="bardelshowp mb10">
                    <div class="sthirdbg"></div>H4 :{" "}
                    {parseFloat(noround(gcount13 * 10000, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div class="sthirdbg"></div>H5 :{" "}
                    {parseFloat(noround(gcount14 * 21000, 2)).toLocaleString()}
                  </div>
                  {/* <div class="bardelshowp mb10">
                    <div
                      class="secondarybardot"
                      style={{ backgroundColor: "red" }}
                    ></div>
                    Other :{" "}
                    {parseFloat(noround(gmcount * 6, 2)).toLocaleString()}
                  </div> */}
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-gseries"
                role="tabpanel"
                aria-labelledby="pills-gseries-tab"
                tabindex="0"
              >
                <div className="chbxhdiv">
                  <ResponsivePie
                    data={dataG}
                    margin={
                      window.innerWidth < 500
                        ? { top: 20, right: 20, bottom: 0, left: 20 }
                        : { top: 40, right: 80, bottom: 40, left: 80 }
                    }
                    valueFormat=" >-,"
                    startAngle={0}
                    sortByValue={false}
                    arcLinkLabelsStraightLength={15}
                    activeOuterRadiusOffset={8}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={20}
                    enableArcLinkLabels={window.innerWidth < 500 ? false : true}
                    enableArcLabels={window.innerWidth < 500 ? false : true}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                    colors={{ scheme: "yellow_orange_red" }}
                    innerRadius={0.45}
                    layers={[
                      "arcs",
                      "arcLabels",
                      "arcLinkLabels",
                      "legends",
                      CenteredMetricG,
                    ]}
                  />
                </div>
                <div class="detailCard secpadding mb20">
                  <div class="bardelshowp mb10">
                    <div class="sfirstbg"></div> G1 :{" "}
                    {parseFloat(noround(gcount1 * 20, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div class="ssecbg"></div>G2 :{" "}
                    {parseFloat(noround(gcount2 * 100, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div class="primarybardot"></div>G3 :{" "}
                    {parseFloat(noround(gcount3 * 500, 2)).toLocaleString()}
                  </div>

                  <div class="bardelshowp mb10">
                    <div class="sthirdbg"></div>G4 :{" "}
                    {parseFloat(noround(gcount4 * 2500, 2)).toLocaleString()}
                  </div>
                  <div class="bardelshowp mb10">
                    <div
                      class="secondarybardot"
                      style={{ backgroundColor: "red" }}
                    ></div>
                    其他 :{" "}
                    {parseFloat(noround(gmcount * 6, 2)).toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default TotaleSupply;
