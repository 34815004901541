import React, { useEffect, useState } from "react";
import MinerCard from "./MinerCard/MinerCard";
import PillsMenu from "./Menu/PillsMenu";
import WalletButton from "./Buttons/WalletButton/WalletButton";
import ConvertCard from "./EGConverter/ConvertCard";

import { useWeb3React } from "@web3-react/core";

export default function Buy() {
  const miners = [21, 22 ,23 , 24, 25 , 26 , 27 , 28];
  const { account, isActive, connector } = useWeb3React();
  return (
    <>
      <div
        class="mainsection"
        style={{
          position: "relative",
        }}
      >
        {/* <div class="minertabsec secpadding lightgrey-bg brrr"> */}
          <ConvertCard />
        {/* </div> */}
        {/* <br /> */}
        <div class="minertabsec secpadding lightgrey-bg brtlr">
          <PillsMenu activeval="buy" />
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-buy"
              role="tabpanel"
              aria-labelledby="pills-buy-tab"
              tabindex="0"
            >
              <div class="tabcontent">
                <div class="row">
                  {miners.map((item, index) => (
                    <MinerCard type={item} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lightblue-bg secpadding brblr mb20">
          <p class="text1">
          欢迎来到Egold矿工商店。请从以上清单中选择矿工进行购买。每个矿工的挖矿功率和价格不
          </p>
        </div>
        <div
          id="overlay"
          style={
            localStorage.getItem("acct") || isActive ? { display: "none" } : {}
          }
        >
          <div className="connectinoverlay">
            {" "}
            <WalletButton />
          </div>
          请连接您的钱包以继续
        </div>
      </div>
    </>
  );
}
