import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MetahashDeployed from "./EGcharts/MetahashDeployed";
import MinerSalesData from "./EGcharts/MinerSalesData";
import MinerMH from "./EGcharts/MinerMH";
import Difficulty from "./EGcharts/Difficulty";
import DifficultyCompare from "./EGcharts/DifficultyCompare";
import ProductionCycles from "./EGcharts/ProductionCycles";
import PillsMenu from "./Menu/PillsMenu";

import { useWeb3React } from "@web3-react/core";

export default function Buy() {
  let navigate = useNavigate();
  const { account, isActive, connector } = useWeb3React();

  const [selectedOption, setSelectedOption] = useState("mhdeployed");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  let selectedComponent;

  if (selectedOption === "mhdeployed") {
    selectedComponent = <MetahashDeployed />;
  } else if (selectedOption === "minerMH") {
    selectedComponent = <MinerMH />;
  } else if (selectedOption === "minersd") {
    selectedComponent = <MinerSalesData />;
  } else if (selectedOption === "sysmd") {
    selectedComponent = <Difficulty />;
  } else if (selectedOption === "egprodcycle") {
    selectedComponent = <ProductionCycles />;
  }
  return (
    <>
      <div class="mainsection">
        <div class="minertabsec secpadding lightgrey-bg brtlr mb20">
          <PillsMenu activeval="stats" />

          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-stats"
              role="tabpanel"
              aria-labelledby="pills-stats-tab"
              tabindex="0"
            >
              <select
                class="form-select darksec-bg text-white mb20"
                id="selectOption"
                aria-label="Default select example"
                onChange={handleSelectChange}
                value={selectedOption}
              >
                <option value="mhdeployed" selected>
                挖矿容量
                </option>
                <option value="minerMH">按矿工类型划分的挖矿能力</option>
                <option value="minersd">按矿工类型出售的单位</option>
                <option value="sysmd">系统挖矿难度</option>
                <option value="egprodcycle">EGOLD生产周期</option>
              </select>
              {selectedComponent}

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
