import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { Spinner } from "reactstrap";
import dataVal from "../../data/Abis.json";
import LoaderCard from "../LoaderCard/LoaderCard";
import ConfirmCard from "../LoaderCard/ConfirmCard";
import ErrorCard from "../LoaderCard/ErrorCard";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function MinerCard(props) {
  let navigate = useNavigate();
  const { account, isActive, connector } = useWeb3React();

  const [isOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(1);
  const [amnt, setAmnt] = useState(null);
  const [tokenBalance, setTokenBalance] = useState("....");
  const [tokenv2Balance, setTokenv2Balance] = useState("....");
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [loadbtn, setloadbtn] = useState(false);
  const [convBtn, setconvBtn] = useState(true);

  const CONTRACT_ADDRESS = dataVal.convertercontract;
  const CONTRACT_ABI = dataVal.converterabi;

  const TOKEN_ABI = dataVal.tokenabi;
  const V1_TOKEN = process.env.REACT_APP_EGOLD_ADDR;
  const V2_TOKEN = process.env.REACT_APP_EGOLDV2_ADDR;

  const tokencontractInstancev1 = new web3.eth.Contract(TOKEN_ABI, V1_TOKEN);
  const tokencontractInstancev2 = new web3.eth.Contract(TOKEN_ABI, V2_TOKEN);

  useEffect(() => {
    getSummary();
  }, []);

  useEffect(() => {
    getSummary();
  }, [account, selectedItem]);

  const getSummary = async () => {
    var wAddress = localStorage.getItem("acct");
    if (wAddress) {
      await tokencontractInstancev1.methods
        .balanceOf(localStorage.getItem("acct"))
        .call()
        .then((value) =>
          setTokenBalance(noround(web3.utils.fromWei(value, "ether"), 3))
        )
        .catch((error) => console.error(error));

      await tokencontractInstancev2.methods
        .balanceOf(localStorage.getItem("acct"))
        .call()
        .then((value) =>
          setTokenv2Balance(noround(web3.utils.fromWei(value, "ether"), 3))
        )
        .catch((error) => console.error(error));

    }
  };

  //To approve From Token for swap
  const handleApprove = async () => {
    try {
      if (localStorage.getItem("acct")) {
        setprocessState({ state: "...", data: null });
        setloadbtn(true);
        const webb3 = new Web3(connector.provider);
        setconvBtn(true);

        const tokencontractInstancev1 = new webb3.eth.Contract(
          TOKEN_ABI,
          V1_TOKEN
        );
        const tokencontractInstancev2 = new webb3.eth.Contract(
          TOKEN_ABI,
          V2_TOKEN
        );

        if (selectedItem == "1") {
          const estimatedGas = await tokencontractInstancev1.methods
            .approve(
              dataVal.convertercontract,
              webb3.utils.toWei(amnt.toString(), "ether")
            )
            .estimateGas({ from: account });

          var method = tokencontractInstancev1.methods
            .approve(
              dataVal.convertercontract,
              webb3.utils.toWei(amnt.toString(), "ether")
            )
            .send({
              from: account,
              gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
              gasPrice: await web3.eth.getGasPrice(),
            });
        } else if (selectedItem == "2") {
          const estimatedGas = await tokencontractInstancev2.methods
            .approve(
              dataVal.convertercontract,
              webb3.utils.toWei(amnt.toString(), "ether")
            )
            .estimateGas({ from: account });

          var method = tokencontractInstancev2.methods
            .approve(
              dataVal.convertercontract,
              webb3.utils.toWei(amnt.toString(), "ether")
            )
            .send({
              from: account,
              gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
              gasPrice: await web3.eth.getGasPrice(),
            });
        }

        await method
          .on("receipt", async function (res) {
            setloadbtn(false);
            setconvBtn(false);
          })
          .on("error", function (error, receipt) {
            setloadbtn(false);
            setconvBtn(true);
            console.log("error", error);
            checkerror(error);
          });
      }
    } catch (e) {
      console.log(e);
      setloadbtn(false);
      checkerror(e);
    }
  };

  const handleConvert = async () => {
    try {
      if (localStorage.getItem("acct")) {
        setprocessState({ state: "...", data: null });
        if (amnt > 0) {
          setprocessState({ state: "processing", data: null });
          const webb3 = new Web3(connector.provider);

          const contractInstance = new webb3.eth.Contract(
            CONTRACT_ABI,
            CONTRACT_ADDRESS
          );

          if (selectedItem == "1") {
            const estimatedGas = await contractInstance.methods
              .migrateV1_to_V2(webb3.utils.toWei(amnt.toString(), "ether"))
              .estimateGas({ from: account });

            var method = contractInstance.methods
              .migrateV1_to_V2(webb3.utils.toWei(amnt.toString(), "ether"))
              .send({
                from: account,
                gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
                gasPrice: await web3.eth.getGasPrice(),
              });
          } else if (selectedItem == "2") {
            const estimatedGas = await contractInstance.methods
              .migrateV2_to_V1(webb3.utils.toWei(amnt.toString(), "ether"))
              .estimateGas({ from: account });

            var method = contractInstance.methods
              .migrateV2_to_V1(webb3.utils.toWei(amnt.toString(), "ether"))
              .send({
                from: account,
                gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
                gasPrice: await web3.eth.getGasPrice(),
              });
          }

          await method
            .on("receipt", async function (res) {
              console.log("success");
              setconvBtn(true);
              setprocessState({ state: "done", data: res.transactionHash });
              getSummary();
            })
            .on("error", function (error, receipt) {
              setloadbtn(false);
              setconvBtn(true);
              console.log("error", error);
              checkerror(error);
            });
        } else {
          setprocessState({
            state: "error",
            data: "请提供有效的输入",
          });
        }
      }
    } catch (e) {
      console.log(e);
      setloadbtn(false);
      checkerror(e);
    }
  };

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (id) => {
    if (id) {
      setSelectedItem(id);
      getSummary();
      setOpen(!isOpen);
    }
  };

  function checkerror(err) {
    if (
      err.message ==
      "Please pass numbers as strings or BN objects to avoid precision errors." ||
      err.message.includes(
        "while converting number to string, invalid number value "
      )
    ) {
      setprocessState({
        state: "error",
        data: "请提供有效的输入",
      });
    } else if (JSON.stringify(err.message).includes("transaction underpriced"))
      setprocessState({
        state: "error",
        data: "交易价格被低估。请尝试提高gas价格",
      });
    else
      setprocessState({
        state: "error",
        data: JSON.stringify(err.message),
      });
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  return (
    <>
      <div class="walletamtsec mb20">
        <div class="lightgrey-bg secpadding brrr">
          <div class="row" style={{ marginBottom: "14px" }}>
            <div class="col-12 d-flex align-items-center justify-content-center">
              <p class="referheading">
                EGOLD (V1){" "}
                <span className="midpart">
                  {"<"}={">"}
                </span>{" "}
                EGOLD (V2){" "}
              </p>
            </div>
          </div>
          <p
            class="fs12"
            style={{
              padding: "12px",
              paddingTop: "0px",
              paddingBottom: "6px",
              color: "#959494",
            }}
          >
            V1 平衡: {tokenBalance}{" "}
            <span
              style={{ float: "right" }}
            >
              V2 平衡: {tokenv2Balance}
            </span>
          </p>
          <div class="detailCard mb20">
            <div class="fromreactangle pr mb20">
              <div class="p15">
                <p class="fs12 mb10">数量</p>
                <p class="fromreactp">
                  {" "}
                  <input
                    className="swapinput"
                    placeholder="0.00"
                    value={amnt}
                    onChange={(e) => setAmnt(e.target.value)}
                  />
                  <a class="selecttokendrop">
                    <span class="float-right fromreactspan dropwrapper">
                      <div class="dropdown">
                        <div class="dropdown-header" onClick={toggleDropdown}>
                          {selectedItem == "1" ? (
                            <span>V1 -{">"} V2</span>
                          ) : (
                            <span>V2 -{">"} V1</span>
                          )}
                          <svg
                            height="15px"
                            viewBox="0 0 24 24"
                            width="12px"
                            fill="#000000"
                          >
                            <path d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                          </svg>
                        </div>
                        <div className={`dropdown-body ${isOpen && "open"}`}>
                          <div
                            class="dropdown-item"
                            onClick={(e) => handleItemClick(e.target.id)}
                            id="1"
                          >
                            <span
                              alt=""
                              id="1"
                              onClick={(e) => handleItemClick(e.target.id)}
                            ></span>
                            V1 -{">"} V2
                            <span
                              className={`dropdown-item-dot ${selectedItem == 1 && "selected"
                                }`}
                            >
                              •{" "}
                            </span>
                          </div>
                          <div
                            class="dropdown-item"
                            onClick={(e) => handleItemClick(e.target.id)}
                            id="2"
                          >
                            <span
                              alt=""
                              id="2"
                              onClick={(e) => handleItemClick(e.target.id)}
                            ></span>
                            V2 -{">"} V1
                            <span
                              className={`dropdown-item-dot ${selectedItem == 2 && "selected"
                                }`}
                            >
                              •{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <span className="egoldicon"></span> */}
                    </span>
                  </a>
                  <span
                    class="float-right yellowtext fs14"
                    style={{
                      cursor: "pointer",
                      marginTop: "6px"
                    }}
                    onClick={() => selectedItem=="1" ? setAmnt(tokenBalance) : setAmnt(tokenv2Balance)}
                  >
                    最大使用
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "4%" }}>
            <a
              className={`btn-color-primary ${!convBtn && "disabled"}`}
              style={{ cursor: "pointer", width: "48%" }}
              onClick={handleApprove}
            >
              {loadbtn ? <Spinner size="sm" /> : "批准"}
            </a>
            <a
              className={`btn-color-primary ${convBtn && "disabled"}`}
              style={{ cursor: "pointer", width: "48%" }}
              onClick={handleConvert}
            >
              转换成 {selectedItem=="1" ? "V2" : "V1"}
            </a>
          </div>
          {processState.state == "..." ? (
            ""
          ) : processState.state == "processing" ? (
            <>
              <br />
              <LoaderCard />
            </>
          ) : processState.state == "done" ? (
            <>
              <br />
              <ConfirmCard tx={processState.data} />
            </>
          ) : (
            <>
              <br />
              <ErrorCard err={processState.data} />
            </>
          )}
        </div>
        {/* <div class="rcard-bg secpadding brblr">
          <p class="text2">
            Lifetime sales reflect the total amount of sales made by your
            account in all currencies, converted to a dollar value. 'Collected'
            indicates the commission you have already claimed, while 'Pending'
            reflects outstanding claims. Click on 'Collect' to claim your
            earnings.
          </p>
        </div> */}
      </div>
    </>
  );
}
