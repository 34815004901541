import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dataVal from "../../data/Abis.json";
const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);
export default function MinerCard(props) {
  let navigate = useNavigate();

  const CONTRACT_ADDRESS = process.env.REACT_APP_INDENTITY_ADDR;
  const CONTRACT_ABI = dataVal.identityabi;
  const contractInstance = new web3.eth.Contract(
    CONTRACT_ABI,
    CONTRACT_ADDRESS
  );
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  return (
    <>
      <div class="walletamtsec mb20">
        <div class="bg-dbg secpadding brtlr">
          <div class="row">
            <div class="col-6 d-flex align-items-center">
              <p class="dbgreferheading">事件</p>
            </div>
            <div
              class="col-6"
              style={{
                textAlign: "right",
                fontWeight: "400",
                fontSize: "11px",
                color: "white",
              }}
            >
              <p>
              开始: <span style={{ marginLeft: "5px" }}>10 Aug 2023</span>
              </p>
              <p>
              结尾: <span style={{ marginLeft: "5px" }}>10 Dec, 2023</span>
              </p>
            </div>
          </div>
          <hr class="mobrefhr" style={{ backgroundColor: "#E2E8F0" }} />
          <div class="d-flex justify-content-between">
            <h5 className="quicktext">
            实现销售目标并有资格参加激动人心的项目会议
              并在泰国曼谷迎接活动
            </h5>
          </div>
        </div>
        <div class="dbg-card secpadding brblr">
          <div class="selectcurbtnsec" style={{ display: "block" }}>
            <a
              class="btn-outline-color-secondary"
              style={{
                cursor: "pointer",
                border: "1px solid #4F6B75",
                width: "100%",
              }}
              onClick={toggleAccordion}
            >
              目标与跟踪
            </a>
            <div className={`teaccordion ${isOpen ? "open" : ""}`}>
              <div className="teaccordion-content">
                {isOpen && (
                  <div
                    class="row"
                    style={{
                      gap: "3%",
                      justifyContent: "center",
                      color: "white",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    <div
                      class="bg-dbg brrr"
                      style={{ width: "46%", paddingBottom: "10px" }}
                    >
                      <div class="row" style={{ padding: "10px 5px" }}>
                        <div class="col-6"></div>
                        <div
                          class="col-6"
                          style={{ textAlign: "right", fontSize: "13px" }}
                        >
                          你的销售额
                        </div>
                      </div>
                      <hr
                        class="mobrefhr"
                        style={{ backgroundColor: "#E2E8F0", marginTop: "0px" }}
                      />
                      <div class="row" style={{ padding: "5px" }}>
                        <div class="col-6" style={{ fontSize: "12px" }}>
                        直接销售
                        </div>
                        <div class="col-6" style={{ textAlign: "right" }}>
                          ...
                        </div>
                      </div>
                      <div class="row" style={{ padding: "5px" }}>
                        <div class="col-6" style={{ fontSize: "12px" }}>
                        间接销售
                        </div>
                        <div class="col-6" style={{ textAlign: "right" }}>
                          ...
                        </div>
                      </div>
                    </div>
                    <div
                      class="bg-dbg brrr"
                      style={{ width: "46%", paddingBottom: "10px" }}
                    >
                      <div class="row" style={{ padding: "10px 5px" }}>
                        <div class="col-6" style={{ fontSize: "13px" }}>
                        直接目标
                        </div>
                        <div
                          class="col-6"
                          style={{ textAlign: "right", fontSize: "13px" }}
                        >
                          间接目标
                        </div>
                      </div>
                      <hr
                        class="mobrefhr"
                        style={{ backgroundColor: "#E2E8F0", marginTop: "0px" }}
                      />
                      <div class="row" style={{ padding: "5px" }}>
                        <div class="col-6">...</div>
                        <div class="col-6" style={{ textAlign: "right" }}>
                          ...
                        </div>
                      </div>
                      <div class="row" style={{ padding: "5px" }}>
                        <div class="col-6"></div>
                        <div class="col-6" style={{ textAlign: "right" }}>
                          ...
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
