import React, { useEffect, useState } from "react";
import BackButton from "./Buttons/BackButton/BackButton";
import { useNavigate, useParams } from "react-router-dom";
const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function Transfer() {
  let navigate = useNavigate();


  return (
    <>
      <div class="mainsection">
        <div class="tabsec secpadding lightgrey-bg brtlr">
          <BackButton Title="Quick Start Bonus Rules" ReRoute="-1" />
          <div class="detailCard secpadding mb20">
            <div style={{padding:"25px"}}>
              <p style={{fontSize:"14px",color:"#101010"}}>1. 用户必须在规定的时间内完成成功步骤才有资格获得奖金.<br/><br/>
2. User can only run independent quick start bonus plan with S1, S2, S3, S4, S5 miners once. <br/><br/>
3. User can only run one plan at a time<br/><br/>
4. The 15 day period will start once user activates plan.<br/><br/>
5. Upon completion, user must make bonus claim and wait for the system to process it.<br/><br/>
6. If a user starts a plan, downline must join with same or higher level miner. For example, if a user starts with an S2 plan, the downline must join with a minimum of an S2 or higher-level miner.<br/><br/>
7. The system will announce the plan end date, and all accounts will be closed at that point, regardless of where the user is within their 15-day plan.</p>

            </div>

         
          </div>
         
        </div>

        <div class="lightblue-bg secpadding brblr mb20">
          <p class="text1">
            You can tranfer your Miner ANFT to another wallet. Please provide
            external wallet address that you would like to send your miner to.
          </p>
        </div>
      </div>
    </>
  );
}
