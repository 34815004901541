import React, { useEffect, useState } from 'react'
import { Spinner } from "reactstrap";
import MinerAccordion from "./MinerAccordion";
export default function InactiveMiners() {
    const [InactiveData, setInActiveData] = useState({ data: [] });
    const [currentSel, setCurrentsel] = useState("all");

    useEffect(() => {
        getSummary();
    }, []);

    const getSummary = async () => {
        const url =
            "https://egold-treasury.tagdev.info/v1/summary/minersOf/" +
            localStorage.getItem("acct");
        fetch(url)
            .then(function (response) {
                return response.json();
            })
            .then(async function (newData) {
                if (newData["detail"] == undefined) {
                    if (newData.length > 0) {
                        console.log("newData", newData);
                        setInActiveData({ data: newData });
                    }
                } else {
                    setInActiveData({ data: [] });
                }
            })
            .catch(function (error) {
                console.log("Requestfailed", error);
            });
    };


    return (
        <div>
            <select
                class="form-select text-white mb20 darkbgselect"
                aria-label="Default select example"
                onChange={(e) => {
                    setCurrentsel(e.target.value);
                }}
                style={{ borderRadius: "8px", backgroundColor: "#648795" }}
            >
                <option value="all">全部</option>
            <option value="S">S 系列</option>
            <option value="G">G 系列</option>
            <option value="H">H 系列</option>
            <option value="X">X 系列</option>

            </select>
            {InactiveData.loading == true ? (
                <div style={{ textAlign: "center" }}>
                    <Spinner />
                </div>
            ) :

                InactiveData.data.length > 0 ? (
                    <div class="mineraccordian" >
                        <div class="accordion" id="accordionExample">
                            {InactiveData.data != null
                                ? InactiveData.data
                                    .slice()
                                    .sort((a, b) => b._id - a._id).map((item) => {
                                        return (
                                            <MinerAccordion
                                                id={item._id}
                                                status={"inactive"}
                                                selected={currentSel}
                                            />
                                        );
                                    })
                                : ""}
                        </div>
                    </div>)
                    : (
                        <p style={{ textAlign: "center" }}>没有可用的矿工数据</p>
                    )}

        </div>
    )
}
